@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  height: -webkit-fill-available;
}
